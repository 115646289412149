// extracted by mini-css-extract-plugin
export var gridContainer = "nav-module--gridContainer--p6jIl";
export var layoutContainer = "nav-module--layoutContainer--EQFfw";
export var link = "nav-module--link--BPVhv";
export var nav = "nav-module--nav--3K70m";
export var ul = "nav-module--ul--p0zoO";
export var li = "nav-module--li--csv1-";
export var a = "nav-module--a--Wf-op";
export var active = "nav-module--active--xJTGe";
export var aboutOutline = "nav-module--aboutOutline--Ev6Nd";
export var projectsOutline = "nav-module--projectsOutline--eNzuy";
export var contactOutline = "nav-module--contactOutline--nqVFV";