// extracted by mini-css-extract-plugin
export var gridContainer = "about-module--gridContainer--Zx4jn";
export var layoutContainer = "about-module--layoutContainer--CBert";
export var link = "about-module--link--PxfZy";
export var sidebar___about = "about-module--sidebar___about--F+6Xh";
export var corner = "about-module--corner--6vd-V";
export var navLink = "about-module--navLink--2mw-0";
export var activeLink = "about-module--activeLink--iXtpq";
export var aboutOutline = "about-module--aboutOutline--lgzNX";
export var imgContainer = "about-module--imgContainer--d+u1j";
export var headshot = "about-module--headshot--tL+eo";
export var h2 = "about-module--h2--Jy-MO";
export var p = "about-module--p--ybQSX";
export var footerTop = "about-module--footerTop--k--nz";
export var projectsOutline = "about-module--projectsOutline--dvGUF";
export var contactOutline = "about-module--contactOutline--BuCbF";