import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Sidebar from "../components/sidebar"
import CornerColor from "../components/cornerColor"
import Layout from "../components/layout"
import Nav from "../components/nav"
import Seo from "../components/seo"
import Menu from "../components/hamburgerMenu"

import * as aboutStyles from "../styles/modules/about.module.scss"

const AboutPage = () => {
  return (
    <>
      <div className={`${"gridContainer"}`}>
        <Sidebar propStyle={aboutStyles.sidebar___about} />
        <CornerColor pageStyle={aboutStyles.corner} />
        <header>
          <Nav
            id="navComponent"
            propActiveStyle={aboutStyles.activeLink}
            propLinkStyle={aboutStyles.navLink}
          />
          <Menu />
        </header>
        <Layout
          layoutContainerStyle={aboutStyles.layoutContainer}
          footerTopStyle={aboutStyles.footerTop}
        >
          <Seo title="About" />
          <h2 className={aboutStyles.h2}>About</h2>
          <div className={aboutStyles.imgContainer}>
            <StaticImage
              className={aboutStyles.headshot}
              src="../images/new_headshot.png"
            />
          </div>
          <p className={aboutStyles.p}>
            Rob Moose is a Grammy Award-winning arranger and string player based
            in New York and Los Angeles. He&apos;s written and recorded charts
            for over 400 albums, including work by Bon Iver, Paul Simon, Taylor
            Swift, John Legend, and
            <a
              className={aboutStyles.link}
              href="https://www.ymusicensemble.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              yMusic.
            </a>
            As an orchestrator, Moose has been repeatedly featured on the
            Declassified Series at the Kennedy Center alongside artists such as
            Regina Spektor, Emily King and Ben Folds. He recently served as
            music director for Sara Bareilles&apos; &quot;Amidst The Chaos&quot;
            tour and is a regular contributor to the podcast{" "}
            <em>Punch Up The Jam</em>.
          </p>
        </Layout>
      </div>
    </>
  )
}

export default AboutPage
